var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reviews-stars flex",attrs:{"data-test":"reviews-star"}},[_vm._l((_vm.bestRating),function(i,index){return [_c('svg',{key:index,staticClass:"reviews-stars__star relative mr-0.5 xxs:mr-2",class:{
        'mr-2':!_vm.small,
        'mr-1':_vm.small,
        'reviews-stars__star--full': index < ~~_vm.rating,
        'reviews-stars__star--half': index < _vm.rating && index === ~~_vm.rating,
        'reviews-stars__star--empty': index >= _vm.rating,
        'hidden':index >= _vm.rating && _vm.hideOverRating
      },attrs:{"width":_vm.small ? 16 : 20,"height":_vm.small ? 16 : 20,"viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"fill":"none","fill-rule":"evenodd"}},[_c('rect',{staticClass:"reviews-stars__star__background",attrs:{"fill":"#f06b1f","width":"20","height":"20","rx":"2"}}),_vm._v(" "),_c('path',{staticClass:"reviews-stars__star__icon",attrs:{"fill":"#fff","d":"m10 12.614-3.919 2.78 1.433-4.586L3.66 7.94l4.804-.055L10 3.333l1.536 4.552 4.804.055-3.854 2.868 1.433 4.585z"}}),_vm._v(" "),_c('rect',{attrs:{"width":"10","height":"20","rx":"2"}}),_vm._v(" "),(index < _vm.rating && index === ~~_vm.rating)?_c('rect',{attrs:{"width":"10","height":"20","x":"10","fill":"#f06b1f"}}):_vm._e()])])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }